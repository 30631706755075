import React from 'react'

function IconProductDescriptionWriter({ fill = 'black', height = 30, width = 30 }) {
  return (

<svg width={width} height={height} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>3</title>
    <g id="3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="wysiwyg_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24" transform="translate(1.5, 1.5)" fill="#000000" fill-rule="nonzero">
            <path d="M1.80775,17 C1.30908333,17 0.883083333,16.8234167 0.52975,16.47025 C0.176583333,16.1169167 0,15.6909167 0,15.19225 L0,1.80775 C0,1.30908333 0.176583333,0.883083333 0.52975,0.52975 C0.883083333,0.176583333 1.30908333,0 1.80775,0 L15.19225,0 C15.6909167,0 16.1169167,0.176583333 16.47025,0.52975 C16.8234167,0.883083333 17,1.30908333 17,1.80775 L17,15.19225 C17,15.6909167 16.8234167,16.1169167 16.47025,16.47025 C16.1169167,16.8234167 15.6909167,17 15.19225,17 L1.80775,17 Z M1.80775,15.5 L15.19225,15.5 C15.2820833,15.5 15.3558333,15.4711667 15.4135,15.4135 C15.4711667,15.3558333 15.5,15.2820833 15.5,15.19225 L15.5,3.5 L1.5,3.5 L1.5,15.19225 C1.5,15.2820833 1.52883333,15.3558333 1.5865,15.4135 C1.64416667,15.4711667 1.71791667,15.5 1.80775,15.5 Z M3.5,8.25 L3.5,6.75 L13.5,6.75 L13.5,8.25 L3.5,8.25 Z M3.5,12.25 L3.5,10.75 L9.5,10.75 L9.5,12.25 L3.5,12.25 Z" id="Shape" fill={fill}></path>
        </g>
    </g>
</svg>
  )
}

export default IconProductDescriptionWriter