import React from 'react'

export default function IconNews({ fill = '#E8EAED', height = 34, width = 34 }) {

  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.51899 29.0423C6.80333 29.0423 6.19759 28.7944 5.70176 28.2986C5.20592 27.8027 4.95801 27.197 4.95801 26.4813V7.51996C4.95801 6.80431 5.20592 6.19857 5.70176 5.70273C6.19759 5.2069 6.80333 4.95898 7.51899 4.95898H22.3667L29.0413 11.6336V26.4813C29.0413 27.197 28.7934 27.8027 28.2976 28.2986C27.8018 28.7944 27.196 29.0423 26.4804 29.0423H7.51899ZM7.51899 26.9173H26.4804C26.6076 26.9173 26.7121 26.8765 26.7938 26.7948C26.8755 26.7131 26.9163 26.6086 26.9163 26.4813V12.7507H21.2497V7.08398H7.51899C7.39172 7.08398 7.28724 7.12483 7.20555 7.20653C7.12386 7.28822 7.08301 7.3927 7.08301 7.51996V26.4813C7.08301 26.6086 7.12386 26.7131 7.20555 26.7948C7.28724 26.8765 7.39172 26.9173 7.51899 26.9173ZM10.2705 23.3757H23.7288V21.2507H10.2705V23.3757ZM10.2705 12.7507H16.9997V10.6257H10.2705V12.7507ZM10.2705 18.0632H23.7288V15.9382H10.2705V18.0632Z" fill={fill} />
    </svg>
  )
}
