import React from 'react'

function IconMetaDataWriter({ fill = 'black', height = 30, width = 28, alignment = 'left' }) {
  const justifyContent = alignment === 'right' ? 'flex-end' : 'flex-start';

  return (
    <div style={{ display: 'flex', justifyContent: justifyContent }}>
      <svg width={width} height={height} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.74967 20.6667H18.083V18.0834H7.74967V20.6667ZM20.6663 20.6667H23.2497V18.0834H20.6663V20.6667ZM7.74967 15.5001H10.333V12.9167H7.74967V15.5001ZM12.9163 15.5001H23.2497V12.9167H12.9163V15.5001ZM5.16634 25.8334C4.45592 25.8334 3.84776 25.5805 3.34186 25.0746C2.83596 24.5687 2.58301 23.9605 2.58301 23.2501V7.75008C2.58301 7.03966 2.83596 6.43151 3.34186 5.9256C3.84776 5.4197 4.45592 5.16675 5.16634 5.16675H25.833C26.5434 5.16675 27.1516 5.4197 27.6575 5.9256C28.1634 6.43151 28.4163 7.03966 28.4163 7.75008V23.2501C28.4163 23.9605 28.1634 24.5687 27.6575 25.0746C27.1516 25.5805 26.5434 25.8334 25.833 25.8334H5.16634ZM5.16634 23.2501H25.833V7.75008H5.16634V23.2501Z" fill={fill} />
      </svg>
    </div>
  )
}

export default IconMetaDataWriter;
