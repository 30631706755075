import React from 'react'

const IconDiamond = ({ fill = '#FFFFFF', stroke = '#FFFFFF', height = 22, width = 22 }) => (
    <svg width={width} height={height} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-40, -974)" fillRule="nonzero">
                <g transform="translate(41, 975)">
                    <path d="M10.4357951,15.3768213 L15.3743229,10.4382935 C15.6151146,10.1975018 15.6151146,9.80621886 15.3743229,9.56542711 L10.4357951,4.62689932 C10.1950033,4.38610756 9.80372043,4.38610756 9.56292868,4.62689932 L4.62321545,9.56424167 C4.38242369,9.80503343 4.38242369,10.1963163 4.62321545,10.4371081 L9.56174324,15.3756359 C9.68329788,15.4971905 9.84073865,15.5574166 9.99934706,15.5574166 C10.1579466,15.5574166 10.3153844,15.4972202 10.4357951,15.3768213 Z" fill={fill}></path>
                    <path d="M10.0006232,20 C15.5144584,20 20,15.5144584 20,9.99937679 C20,4.48554157 15.5144584,0 10.0006232,0 C4.48554157,0.00121712576 0,4.48554157 0,9.99937679 C0,15.5144584 4.48554157,20 10.0006232,20 Z" stroke={stroke}></path>
                </g>
            </g>
        </g>
    </svg>
);

export default IconDiamond;