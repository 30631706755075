import React from 'react'

function IconProductDescriptionGenerator({ fill = 'black', height = 30, width = 30 }) {
  return (
    <svg height={height} width={width} viewBox="0 0 20 20">
        <g id="Artboard-Copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="2" transform="translate(1.5, 1.5)" fill="#000000" fill-rule="nonzero">
                <path d="M12.2885,17 L1.80775,17 C1.30258333,17 0.875,16.825 0.525,16.475 C0.175,16.125 0,15.6974167 0,15.19225 L0,1.80775 C0,1.30258333 0.175,0.875 0.525,0.525 C0.875,0.175 1.30258333,0 1.80775,0 L15.19225,0 C15.6974167,0 16.125,0.175 16.475,0.525 C16.825,0.875 17,1.30258333 17,1.80775 L17,12.2885 L12.2885,17 Z M11.5,15.5 L11.5,13.5 C11.5,12.95 11.6958333,12.4791667 12.0875,12.0875 C12.4791667,11.6958333 12.95,11.5 13.5,11.5 L15.5,11.5 L15.5,1.80775 C15.5,1.73075 15.4679167,1.66025 15.40375,1.59625 C15.33975,1.53208333 15.26925,1.5 15.19225,1.5 L1.80775,1.5 C1.73075,1.5 1.66025,1.53208333 1.59625,1.59625 C1.53208333,1.66025 1.5,1.73075 1.5,1.80775 L1.5,15.19225 C1.5,15.26925 1.53208333,15.33975 1.59625,15.40375 C1.66025,15.4679167 1.73075,15.5 1.80775,15.5 L11.5,15.5 Z M7.75,12.25 L9.25,12.25 L9.25,6.25 L12.25,6.25 L12.25,4.75 L4.75,4.75 L4.75,6.25 L7.75,6.25 L7.75,12.25 Z M1.5375,15.5 L1.5375,1.5 L1.5375,15.5 Z" id="Shape" fill={fill}></path>
            </g>
        </g>
    </svg>
  )
}

export default IconProductDescriptionGenerator