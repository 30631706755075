import React from 'react'

function IconImageAltTextGenerator({ fill = 'black', height = 30, width = 30 }) {
  return (
<svg width={width} height={height} viewBox="0 0 20 20" version="1.1">
    <g id="6" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="tooltip_2_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24" transform="translate(1, 1)" fill="#000000" fill-rule="nonzero">
            <path d="M3.55263158,10.7763158 L10.6578947,10.7763158 L10.6578947,9.35526316 L3.55263158,9.35526316 L3.55263158,10.7763158 Z M3.55263158,7.93421053 L14.4473684,7.93421053 L14.4473684,6.51315789 L3.55263158,6.51315789 L3.55263158,7.93421053 Z M3.55263158,5.09210526 L14.4473684,5.09210526 L14.4473684,3.67105263 L3.55263158,3.67105263 L3.55263158,5.09210526 Z M9,18 L6.64792105,14.4473684 L1.71260526,14.4473684 C1.24160526,14.4473684 0.838421053,14.2796842 0.503052632,13.9443158 C0.167684211,13.6089474 0,13.2057632 0,12.7347632 L0,1.71260526 C0,1.24160526 0.167684211,0.838421053 0.503052632,0.503052632 C0.838421053,0.167684211 1.24160526,0 1.71260526,0 L16.2873947,0 C16.7583947,0 17.1615789,0.167684211 17.4969474,0.503052632 C17.8323158,0.838421053 18,1.24160526 18,1.71260526 L18,12.7347632 C18,13.2057632 17.8323158,13.6089474 17.4969474,13.9443158 C17.1615789,14.2796842 16.7583947,14.4473684 16.2873947,14.4473684 L11.3520789,14.4473684 L9,18 Z M9,15.4366579 L10.5887368,13.0263158 L16.2873947,13.0263158 C16.3725,13.0263158 16.4423684,12.999 16.497,12.9443684 C16.5516316,12.8897368 16.5789474,12.8198684 16.5789474,12.7347632 L16.5789474,1.71260526 C16.5789474,1.6275 16.5516316,1.55763158 16.497,1.503 C16.4423684,1.44836842 16.3725,1.42105263 16.2873947,1.42105263 L1.71260526,1.42105263 C1.6275,1.42105263 1.55763158,1.44836842 1.503,1.503 C1.44836842,1.55763158 1.42105263,1.6275 1.42105263,1.71260526 L1.42105263,12.7347632 C1.42105263,12.8198684 1.44836842,12.8897368 1.503,12.9443684 C1.55763158,12.999 1.6275,13.0263158 1.71260526,13.0263158 L7.41126316,13.0263158 L9,15.4366579 Z" id="Shape" fill={fill}></path>
        </g>
    </g>
</svg>

  )
}

export default IconImageAltTextGenerator