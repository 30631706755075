import React from 'react'

const IconStar = ({ stroke = "#ffffff", height = "38px", width = "39px" }) => (
    <svg width={width} height={height} viewBox={`0 0 39 38`} xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Step-1" transform="translate(-33, -190)" stroke={stroke} strokeWidth="2.5">
                <path d="M57.236,200.639 L58.4034235,205.332818 C58.7490049,206.714981 59.6140502,208.12537 60.7568816,209.268202 C61.8996967,210.411017 63.3100462,211.276036 64.6937989,211.621965 L69.385,212.788 L64.6921663,213.954754 C63.4087296,214.275648 62.1009865,215.044435 61.0057152,216.067623 L60.7568398,216.308172 C59.6140282,217.450983 58.7490003,218.861335 58.4030054,220.245172 L57.237,224.936 L56.0703116,220.243535 C55.749461,218.960105 54.9806973,217.652367 53.9575279,216.557095 L53.7169839,216.30822 C52.5741711,215.165385 51.1638062,214.300339 49.7799902,213.954338 L45.088,212.788 L49.7816326,211.621565 C51.06504,211.30071 52.3727895,210.531918 53.4680656,209.508708 L53.7169422,209.268154 C54.8597537,208.125321 55.7247757,206.714936 56.0707129,205.331168 L57.236,200.639 Z M41.5061873,193.353713 L42.7482903,195.321553 C43.1998844,196.34981 44.3355589,197.447043 45.3160944,197.806489 L47.143452,198.506337 L45.1756018,199.748473 C44.1473445,200.200074 43.0501382,201.335776 42.6907138,202.316351 L41.9908965,204.14368 L40.7487969,202.175845 C40.2972009,201.147558 39.1615279,200.05035 38.1809895,199.690904 L36.3536342,198.991056 L38.3214944,197.748911 C39.3497477,197.29729 40.4469485,196.161584 40.8063679,195.181047 L41.5061873,193.353713 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
);

export default IconStar;