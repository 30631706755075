/**
 * Register data sources 
 * */

/* Ecommerce */
const bigcommerce = require('./bigcommerce');
const shopify = require('./shopify');
// const commerceTools = require('./commerce-tools');

/* CMS */
const wordpress = require('./wordpress');
const storyblok = require('./storyblok');
// const builderIo = require('./builder-io');
// const contentful = require('./contentful');

const registeredSources = {
    bigcommerce,
    shopify,
    // commerceTools

    wordpress,
    storyblok,
    // builderIo,
    // contentful,
}

const getDataTypes = (dataSource) => {
    if (dataSource !== '' && registeredSources[dataSource]) {
        return registeredSources[dataSource].dataTypes();
    }

    return [];
};

const getDataByType = async (client, instanceKey, dataSource, dataType, query) => {
    if (dataSource !== '' && registeredSources[dataSource] && dataType !== '') {
        return await registeredSources[dataSource].getDataByType(client, instanceKey, dataType, query);
    }

    return [];
};

const updateData = async (client, instanceKey, dataSource, dataType, data) => {
    if (dataSource !== '' && registeredSources[dataSource]) {
        return registeredSources[dataSource].updateData(client, instanceKey, dataType, data);
    }

    return false;
}

export {
    getDataTypes,
    getDataByType,
    updateData
}