import React from 'react'

function IconArticleWriter({ fill = 'black', height = 30, width = 30 }) {
  return (
<svg width={width} height={height} viewBox="0 0 20 20" version="1.1">
    <title>1</title>
    <g id="1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(1.6, 2)" fill="#000000" fill-rule="nonzero" id="Shape">
            <path d="M0,9.5 L0,8 L7,8 L7,9.5 L0,9.5 Z M0,5.5 L0,4 L11,4 L11,5.5 L0,5.5 Z M0,1.5 L0,0 L11,0 L11,1.5 L0,1.5 Z M8.6155,15 L8.6155,12.35775 L14.04425,6.95375 C14.1685833,6.82941667 14.3028333,6.74191667 14.447,6.69125 C14.5911667,6.64075 14.7353333,6.6155 14.8795,6.6155 C15.0366667,6.6155 15.18875,6.64491667 15.33575,6.70375 C15.48275,6.76275 15.6163333,6.85125 15.7365,6.96925 L16.6615,7.90375 C16.7691667,8.02825 16.8525,8.16291667 16.9115,8.30775 C16.9705,8.45258333 17,8.59741667 17,8.74225 C17,8.88708333 16.9730833,9.0345 16.91925,9.1845 C16.8654167,9.3345 16.7795,9.47166667 16.6615,9.596 L11.25775,15 L8.6155,15 Z M15.80775,8.74225 L14.88275,7.80775 L15.80775,8.74225 Z M9.80775,13.80775 L10.75775,13.80775 L14.00375,10.546 L13.54425,10.071 L13.07875,9.602 L9.80775,12.85775 L9.80775,13.80775 L9.80775,13.80775 Z M13.54425,10.071 L13.07875,9.602 L14.00375,10.546 L13.54425,10.071 Z"fill={fill}></path>
        </g>
    </g>
</svg>
  )
}

export default IconArticleWriter