import React from 'react'

function IconProductSEOOptimizer({ fill = 'black', height = 30, width = 30 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 23.75V21.25H15V23.75H2.5ZM2.5 17.5V15H8.75V17.5H2.5ZM2.5 11.25V8.75H8.75V11.25H2.5ZM25.75 23.75L20.9375 18.9375C20.4375 19.2917 19.8906 19.5573 19.2969 19.7344C18.7031 19.9115 18.1042 20 17.5 20C15.7708 20 14.2969 19.3906 13.0781 18.1719C11.8594 16.9531 11.25 15.4792 11.25 13.75C11.25 12.0208 11.8594 10.5469 13.0781 9.32812C14.2969 8.10938 15.7708 7.5 17.5 7.5C19.2292 7.5 20.7031 8.10938 21.9219 9.32812C23.1406 10.5469 23.75 12.0208 23.75 13.75C23.75 14.3542 23.6615 14.9531 23.4844 15.5469C23.3073 16.1406 23.0417 16.6875 22.6875 17.1875L27.5 22L25.75 23.75ZM17.5 17.5C18.5417 17.5 19.4271 17.1354 20.1562 16.4062C20.8854 15.6771 21.25 14.7917 21.25 13.75C21.25 12.7083 20.8854 11.8229 20.1562 11.0938C19.4271 10.3646 18.5417 10 17.5 10C16.4583 10 15.5729 10.3646 14.8438 11.0938C14.1146 11.8229 13.75 12.7083 13.75 13.75C13.75 14.7917 14.1146 15.6771 14.8438 16.4062C15.5729 17.1354 16.4583 17.5 17.5 17.5Z" fill={fill} />
    </svg>
  )
}

export default IconProductSEOOptimizer