import React from 'react'

function IconMetaDataOptimiser({ fill = 'black', height = 30, width = 30 }) {
  return (
<svg  width={width} height={height} viewBox="0 0 20 20" version="1.1">
    <g id="5" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="integration_instructions_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24" transform="translate(1.5, 0.5)" fill="#000000" fill-rule="nonzero">
            <path d="M6.1,13.7345 L7.15375,12.65 L5.00375,10.5 L7.15375,8.35 L6.1,7.2655 L2.8655,10.5 L6.1,13.7345 Z M10.9,13.7345 L14.1345,10.5 L10.9,7.2655 L9.84625,8.35 L11.99625,10.5 L9.84625,12.65 L10.9,13.7345 Z M1.80775,19 C1.30908333,19 0.883083333,18.8234167 0.52975,18.47025 C0.176583333,18.1169167 0,17.6909167 0,17.19225 L0,3.80775 C0,3.30908333 0.176583333,2.88308333 0.52975,2.52975 C0.883083333,2.17658333 1.30908333,2 1.80775,2 L6.25775,2 C6.32058333,1.44483333 6.56291667,0.97275 6.98475,0.58375 C7.40641667,0.194583333 7.9115,0 8.5,0 C9.09483333,0 9.60316667,0.194583333 10.025,0.58375 C10.4468333,0.97275 10.6859167,1.44483333 10.74225,2 L15.19225,2 C15.6909167,2 16.1169167,2.17658333 16.47025,2.52975 C16.8234167,2.88308333 17,3.30908333 17,3.80775 L17,17.19225 C17,17.6909167 16.8234167,18.1169167 16.47025,18.47025 C16.1169167,18.8234167 15.6909167,19 15.19225,19 L1.80775,19 Z M1.80775,17.5 L15.19225,17.5 C15.26925,17.5 15.33975,17.4679167 15.40375,17.40375 C15.4679167,17.33975 15.5,17.26925 15.5,17.19225 L15.5,3.80775 C15.5,3.73075 15.4679167,3.66025 15.40375,3.59625 C15.33975,3.53208333 15.26925,3.5 15.19225,3.5 L1.80775,3.5 C1.73075,3.5 1.66025,3.53208333 1.59625,3.59625 C1.53208333,3.66025 1.5,3.73075 1.5,3.80775 L1.5,17.19225 C1.5,17.26925 1.53208333,17.33975 1.59625,17.40375 C1.66025,17.4679167 1.73075,17.5 1.80775,17.5 Z M8.5,2.84625 C8.71666667,2.84625 8.89583333,2.77541667 9.0375,2.63375 C9.17916667,2.49208333 9.25,2.31291667 9.25,2.09625 C9.25,1.87958333 9.17916667,1.70041667 9.0375,1.55875 C8.89583333,1.41708333 8.71666667,1.34625 8.5,1.34625 C8.28333333,1.34625 8.10416667,1.41708333 7.9625,1.55875 C7.82083333,1.70041667 7.75,1.87958333 7.75,2.09625 C7.75,2.31291667 7.82083333,2.49208333 7.9625,2.63375 C8.10416667,2.77541667 8.28333333,2.84625 8.5,2.84625 Z M1.5375,17.5 L1.5375,3.5 L1.5375,17.5 Z" id="Shape" fill={fill}></path>
        </g>
    </g>
</svg>
  )
}

export default IconMetaDataOptimiser